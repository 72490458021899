.fade-appear {
    opacity: 0;
}
.fade-appear-active {
    opacity: 1;
    transition: opacity 200ms;
}
.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}