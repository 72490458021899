body, html {
  height: 100%;
  min-height: 1024px;
}

#root {
  height: 100%;
  display: flex;
  overflow: auto;
  background: rgb(16,92,154);
  background: linear-gradient(180deg, rgba(16,92,154,1) 0%, rgba(0,10,96,1) 100%);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}